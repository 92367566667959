@import 'primeicons/primeicons.css';

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
  scroll-behavior: smooth;
  background-color: var(--surface-ground);
}

@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}
